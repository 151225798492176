/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 16:06:57
 * @LastEditTime: 2021-07-06 16:11:01
 * @LastEditors: mulingyuer
 * @Description:通用数据
 * @FilePath: \saas-admin-vue\src\modules\cert-manage\data\default.js
 * 怎么可能会有bug！！！
 */

//证书类型选项数据
export const typeArr = [
  { name: "全部", id: -1 },
  { name: "系统模板", id: 0 },
  { name: "自建模板", id: 1 },
];

//推送渠道选项数据
export const pushTypeArr = [
  { name: "全部", id: -1 },
  { name: "系统消息", id: 0 },
  { name: "短信消息", id: 1 },
  { name: "系统消息、短信消息", id: 2 },
];

//证书状态
export const statusArray = [
  { name: "全部", id: -1 },
  { name: "已收回", id: 0 },
  { name: "有效", id: 1 },
  { name: "已过期", id: 2 },
];