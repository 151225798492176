<!--
 * @Author: mulingyuer
 * @Date: 2021-07-06 14:03:02
 * @LastEditTime: 2022-01-04 17:44:08
 * @LastEditors: aleaner
 * @Description: 筛选
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\components\list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">

      <div class="filter-item">
        <el-form-item label="证书名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入证书名称"></el-input>
        </el-form-item>
        <el-form-item label="奖项名称：" prop="prize_name">
          <el-input v-model="form.prize_name" placeholder="请输入奖项名称"></el-input>
        </el-form-item>
        <el-form-item label="证书类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择证书类型">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="推送渠道：" prop="push_type">
          <el-select v-model="form.push_type" placeholder="请选择推送渠道">
            <el-option v-for="op in pushTypeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-placeholder="开始日期" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" end-placeholder="结束日期" :end-time.sync="form.create_end_time"
          end-prop="create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { typeArr, pushTypeArr } from "../../data/default";
import { documentCertList } from "../../api/list";
export default {
  props: {
    //筛选回调
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        create_start_time: -1, // 开始时间
        create_end_time: -1, // 结束时间
        name: "", //证书名称
        prize_name: "", //证书奖项名称
        type: -1, //证书类型
        push_type: -1, //推送渠道
      },
      rules: {},
      typeArr, //证书类型选项数据
      pushTypeArr, //推送渠道选项数据
    };
  },
  methods: {
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onFilter();
    },
    //导出
    onExport() {
      documentCertList({ ...this.exportData, ...this.form }).catch((err) => {});
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
