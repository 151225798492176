/*
 * @Author: mulingyuer
 * @Date: 2021-07-06 14:49:39
 * @LastEditTime: 2022-01-04 17:37:06
 * @LastEditors: aleaner
 * @Description: 证书列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\api\list.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


//列表
export const list = data => {
  return api({
    url: '/admin/cert/cert/index',
    method: 'post',
    data
  })
}


//删除证书
export const certDelete = data => {
  return api({
    url: "/admin/cert/cert/delete",
    method: 'post',
    data
  })
}

// 证书列表导出
export const documentCertList = data => {
  return fetchFile({
    url: "/admin/cert/cert/export",
    method: "post",
    data,
    download: true
  });
};