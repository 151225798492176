<!--
 * @Author: mulingyuer
 * @Date: 2021-07-06 13:58:30
 * @LastEditTime: 2022-01-04 17:42:03
 * @LastEditors: aleaner
 * @Description: 证书列表
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\cert-manage\views\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page cert-list">
    <div class="head">
      <div class="head-btn">
        <el-button size="small" type="primary" @click="onAdd">新增</el-button>
      </div>
      <ListFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-btn">
        <el-button size="small" @click="onMultipleDelete">删除</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :prop="th.prop"
          :label="th.label" :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed"
          :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button type="text" size="mini" @click="onManage(row.id)">管理</el-button>
              <el-button type="text" size="mini" @click="onDelete(row.id)">删除</el-button>
            </template>
            <!-- 有效/已颁发 -->
            <span v-else-if="th.prop==='effect_issue'">
              {{row.effect_cert|placeholder}}/{{row.records_count|placeholder}}
            </span>
            <!-- 其他 -->
            <template v-else>{{row[th.prop] | placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
  </div>
</template>

<script>
import ListFilter from "../components/list/Filter";
import Pagination from "@/base/components/Default/Pagination";
import { list, certDelete } from "../api/list";
export default {
  data() {
    return {
      loading: false, //加载中
      postData: {},
      thead: [
        { label: "证书ID", prop: "id", minWidth: 120, sort: "custom" },
        { label: "证书名称", prop: "name", minWidth: 150, tooltip: true },
        { label: "奖项名称", prop: "prize_name", minWidth: 150, tooltip: true },
        { label: "证书类型", prop: "type_text", minWidth: 120 },
        { label: "有效证书/已颁发证书", prop: "effect_issue", minWidth: 180 },
        { label: "推送渠道", prop: "push_text", minWidth: 120 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 180,
          sort: "custom",
        },
        {
          label: "操作",
          prop: "operate",
          minWidth: 120,
          fixed: "right",
        },
      ],
      tbody: [],
      pageData: {},
      activeCertIds: [], //选择的id
    };
  },
  methods: {
    //获取列表数据
    getList(pageData) {
      this.loading = true;
      list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选
    onFilter(data) {
      Object.assign(this.postData, data, { page: 1 });
      this.getList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { is_desc, order_by: prop }, { page: 1 });
      this.getList();
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeCertIds = arr.map((item) => item.id);
      } else {
        this.activeCertIds = [];
      }
      Object.assign(this.postData, { export_ids: this.activeCertIds });
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
    //单条删除
    onDelete(id, multiple = false) {
      let infoText = `您是否要删除${multiple ? "选中的" : "该条"}证书数据？`;
      let api = certDelete;
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { id };
          api(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              //更新
              this.getList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeCertIds.length <= 0) {
        return this.$message("请选择数据项");
      }
      this.onDelete(this.activeCertIds, true);
    },
    //管理
    onManage(id) {
      this.$router.push({ name: "CertManageList", params: { id } });
    },
    //新增
    onAdd() {
      this.$router.push({ name: "CertAdd" });
    },
  },
  created() {
    this.getList();
  },
  components: {
    ListFilter,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
